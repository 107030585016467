export default {
  header: {
    title: 'Exclusive offers for you for today only. Register for your Free Account',
    profileText: 'just made'
  },
  hero: {
    title: 'TRADE BITCOIN VOLATILITY LIKE A PRO THROUGH {trademark}',
    subtitle: 'Unlock Immense Wealth Today!'
  },
  contact: {
    title: 'Create an account',
    firstNamePlc: 'First name',
    lastNamePlc: 'Last name',
    emailPlc: 'Email',
    phonePlc: 'Telephone',
    buttonText: 'Register now'
  },
  joinUs: {
    title: 'Buy Crypto Now or Lose Big',
    text1: 'Have you heard? The richest man in the world just sank $1.5 Billion of Tesla’s money into Bitcoin and nearly doubled its value, which means there’s no way you can ignore crypto any longer. And very soon, you could buy a brand new Tesla with Bitcoin!',
    text2: 'It started on Jan 29th, 2021. Elon Musk – worth $197 billion and counting – changed the status on his Twitter profile to the Bitcoin hashtag and published a cryptic but powerful endorsement of the world’s first cryptocurrency: "In retrospect it was inevitable".',
    text3: 'Those five words skyrocketed the price up from $32,299 to over $50,000 today, and that’s just the beginning! Big names in US banking like Goldman Sachs and JP Morgan are waking up to Bitcoin too, and the more they invest, the higher the price goes.',
    text4:'But Elon Musk is the face of this revolution and the real reason to listen. Can you afford to ignore Elon any longer? He’s telling the world that Bitcoin is the future of money, so it’s only going to surge to even more ridiculous record-breaking prices. Do you want to be left behind again?',
    text5:'But hey, it isn’t just Bitcoin that’s caught Elon’s eye. Check out this tweet on Dogecoin too -',
    text6: 'Elon Musk didn’t become the richest man in the world by accident. Everything he does makes money, so why not be smart and follow Elon’s magic money trail? BitcoinX is offering you the easy way to help you do exactly that. Sign up now and get ready to change your life forever with Crypto, just like our users have.'
  },
  testimonial: {
    title: 'TESTIMONIALS FROM HAPPY CLIENTS',
    client1: {
      name: 'Collin B.',
      city: 'Budapest',
      profit: 'Profit $ {sum}',
      testimonial: '“Bitcoin trading has changed my life. I was on the verge of giving up and declaring bankruptcy until I stumbled upon the {trademark} app. Within months of trading, at least 80% of my debts are paid, and I have a stable daily income.”'
    },
    client2: {
      name: 'Alexia R.',
      city: 'Edmonton',
      profit: 'Profit $ {sum}',
      testimonial: '“The covid19 pandemic has been a blessing in disguise for me. I started using the {trademark} out of boredom, but now it is my main source of income. A profit of up to $5000 daily is something I never thought I would achieve in this economy.”'
    },
    client3: {
      name: 'Tony R.',
      city: 'Dubai',
      profit: 'Profit $ {sum}',
      testimonial: '“This trading app is just amazing. I am earning ten times more than I used to work for 12 hours a day as an insurance salesman. The most amazing thing is that I can still generate profits, even when sleeping.”'
    },
    client4: {
      name: 'Laure M.',
      city: 'Belfast',
      profit: 'Profit $ {sum}',
      testimonial: '“I registered with {trademark} after reading its review on one of the leading crypto publications. It’s surprising how profitable volatility trading can get through this robot. I am making more profits than I have ever made in stock trading.”'
    }
  },
  features: {
    feature1: {
      title: 'EASY TO USE PLATFORM',
      text: '{trademark} App is web-based. You can access us from major browsers both on desktop and mobile devices. We recommend that you use reputable browsers such as Chrome, Firefox, Safari, and Brave for security purposes. You can download the HTML5 version of our web-trader for your mobile devices.'
    },
    feature2: {
      title: 'TOP-NOTCH SECURITY FEATURES',
      text: 'We value our users’ safety and have, therefore, invested in foolproof systems. Our website has military-grade encryption, and we are observant of global data protection measures. These include top-level measures such as the EU General Data Protection Regulation (GDPR). Our brokers are also reputable and regulated in all jurisdictions.'
    },
    feature3: {
      title: 'AFFORDABLE TRADING CAPITAL',
      text: 'Most of our competing trading systems only accept investors with at least $1 million in trading capital. This means that they are out of reach for most people. We are the first AI-based trading system to allow traders to participate in auto-trading with a deposit of as little as USD250.'
    }
  },
  live: {
    btnJoin: 'Join now',
    title: 'LIVE PROFIT RESULTS',
    table: {
      col1: {
        th: 'The {trademark}',
        row1: 'Jacque S. just won trade...',
        row2: 'Jamie W. just won trade...',
        row3: 'Dom E. just won trade...',
        row4: 'Jack T. just won trade...',
        row5: 'Izzie W. just won trade...',
        row6: 'Poppy B. just won trade...',
        row7: 'Edward H. just won trade...',
        row8: 'Laura M. just won trade...',
        row9: 'Sally P. just won trade...',
        row10: 'Rachael S. just won trade...'
      },
      col2: {
        th: 'Profit'
      },
      col3: {
        th: 'Trade Time'
      },
      col4: {
        th: 'Cryptocurrency'
      },
      col5: {
        th: 'Result'
      },
    }
  },
  howItWorks: {
    title: 'How does {trademark} work?',
    contactBtn: 'Open free account',
    step1: {
      cardText: 'Step 1',
      title: 'Register on the Site',
      text: 'Register a free trading account using the form at the top of this page. The process is straightforward and takes less than 5 minutes. We handle all our users’ data with strict confidentiality.'
    },
    step2: {
      cardText: 'Step 2',
      title: 'Fund Your Account',
      text: 'After signup, we will redirect you to our partner broker’s page to deposit funds in your trading account. All of our financial transactions are handled through reputable, highly regulated brokers.'
    },
    step3: {
      cardText: 'Step 3',
      title: 'Start Trading',
      text: 'Live trading happens at the click of a button. You can go about your daily business as our trading system works for you in the background. A deposit of $250 is all it takes to kick-start your journey to becoming a successful BitcoinX.'
    }
  },
  faqTop: {
    title: 'FREQUENTLY ASKED QUESTIONS',
    item1: {
      question: 'Is {trademark} beginner friendly?',
      answer: 'We are an easy to use and extremely profitable trading system. Below are some of the features that make us stand amongst competitors.'
    },
    item2: {
      question: 'How much profit can I make with {trademark}?',
      answer: 'For consistent and lucky traders, a deposit of $250 can grow to $1 million through compounding in less than a year. There are substantial risks in all forms of trading, and hence you should invest wisely.'
    },
    item3: {
      question: 'How much does {trademark} cost?',
      answer: 'We are offering free registration slots on a lottery basis so try your luck by attempting to register through the form above. Those who will manage to secure the free license will trade for free for the next year.'
    },
    item4: {
      question: 'Do I need trading expertise to use {trademark}?',
      answer: 'You can make money through {trademark} from the first day of trading even with zero trading experience. This is because our trading system conducts all the technical trading functions on autopilot.'
    },
    item5: {
      question: 'Is {trademark} a Ponzi scheme?',
      answer: 'No! {trademark} is not a Ponzi scheme. A lot of people are asking this question due to the insane profitability reported by this bot. We use Artificial Intelligence technologies and high leverage as provided by our partner brokers to ensure such high profitability.'
    },
    item6: {
      question: 'How long does it take to trade?',
      answer: '{trademark} trades automatically and hence not more than 20 minutes daily are needed to set up the conditions for trading. This means that users do not have to forego their day jobs or spend sleepless nights monitoring the markets.'
    }
  },
  faqBottom: {
    title: 'A Deep Dive on {trademark}',
    whatIs: {
      title: 'What is {trademark}?',
      text: '{trademark} is a powerful AI algorithm made out of secret trading strategies developed by professional traders. Our founders have generated massive profits for investors using advanced trading systems.'
    },
    howDoes: {
      title: 'How does {trademark} work?',
      text1: '{trademark} algorithms are automation of over 15 trading strategies applied in fast-paced trading. These include scalping, a technique that involves program placing tens of trades within a microsecond and profiting off small price movements.',
      text2: 'The outcome on the small price movement is magnified by leverage of 5000:1 as provided by our partner brokers. For the record, leverage of 5000:1 implies that you can trade positions worth $5000 for every $1 you invest.',
      text3: '1. Sign up with {trademark} through the form above and get your account approved by verifying your contact details. You redirect to one of our highly reputable brokers to verify ID and proof address. This is a crucial security measure implemented by all top brokers.',
      text4: '2. Fund your account to be able to place orders in the markets. Remember that the minimum balance required is USD250 and all orders are placed automatically. You can trade before verification but please note that your account must be verified to be able to withdraw.',
      text5: '3. Read {trademark} trading instructions manual and test your understanding of the demo. You will also be assigned a dedicated account manager at this stage to help you through the trading process.',
      text6: '4. Switch on the robot and sit back and relax as money trickles in your account. Under favourable trading conditions, {trademark} can multiply a trading account fivefold.'
    },
    facts: {
      title: 'Interesting Facts about {trademark}',
      text1: 'In September 2008, Lehman Brothers, the fourth-largest investment bank at the time, filed for bankruptcy. The systemic risk resulting from the subprime mortgages was taking a toll on the banks, and Lehman’s had the most exposure.',
      text2: 'Billions of dollars’ worth of investor money were lost while hundreds of people were rendered jobless. Our founder was at the time a senior portfolio manager at the only Wall Street hedge fund and the founder of an advanced trading system for financial derivatives.',
      text3: 'The fall of Lehman’s was a turning point for the {trademark} trading software after it generated over 80 billion dollars within 24 hours. In the next three years, the system was to generate nearly one trillion dollars for investors.',
      text4: 'In 2016, he patented the algorithm and decided to use it for bitcoin trading. It was not until 2016 that {trademark} became available to the general public. In the bitcoin boom of 2016/2017, the robot generated over $500,000 billion in profits.',
      text5: 'A lot of people have gained financial freedom through us. You can also become part of our story by signing up today.'
    },
    famousThinks: {
      title: 'Did Elon Musk and Gordan Ramsey endorse {trademark}?',
      text1: 'Some gossip blogs have written viral posts claiming that we have received investment from celebrity entrepreneurs. But is this true?',
      text2: '<b>{trademark} and Elon Musk –</b> Elon Musk is a tech entrepreneur with vast interests in AI and blockchain. He indeed has some bitcoin in his investment portfolio, but he hasn’t committed any money to us.',
      text3: '<b>{trademark} and Gordon Ramsay –</b> The British celebrity chef is also not among the celebrities that have invested a lot of money in our trading system.',
      text4: 'There are also rumours that {trademark} has appeared on the Shark Tank, Dragons Den UK, and Good Morning America. We haven’t been featured in any of these shows.'
    },
    review: {
      title: '{trademark} Review: The Verdict!',
      text1: 'Our trading system has a win rate of 88% and an accuracy level of 90%. This means that nearly 9 out of ten people who try us make money. High leverage, coupled with high accuracy produces astronomical profits.',
      text2: '{trademark} is powered by reputable robot brokers whose roles include facilitating trading. Our highly trustworthy partner brokers handle all deposits made through our platform. You can try {trademark} for yourself by scrolling to the top of this page and registering a free account through the provided form.'
    }
  },
  footer: {
    btnGetStarted: 'Get started now',
    text: '<b>Disclaimer:</b> CFDs trading, whether manually or through a highly leveraged bot, involve a lot of risks. Consequently, only risk what you can afford to lose. The information presented on this page shouldn’t be relied upon as a basis for decision making. Conduct enough background research before signing up with us. By registering with us, you agree with our terms and conditions and privacy policy. Do not register with us if you are against us earning a commission by linking you to third party platforms.'
  }
}
