import { render, staticRenderFns } from "./whyChoose.vue?vue&type=template&id=da6f56fe&scoped=true"
var script = {}
import style0 from "./whyChoose.vue?vue&type=style&index=0&id=da6f56fe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da6f56fe",
  null
  
)

export default component.exports