export default {
  header: {
    title: 'Эксклюзивные предложения для вас только на сегодня. Зарегистрируйтесь для получения бесплатного аккаунта',
    profileText: 'только что сделала'
  },
  hero: {
    title: 'ВОЛАТИЛЬНОСТЬ ТОРГОВЛИ БИТКОИНАМИ КАК ПРОФИ {trademark}',
    subtitle: 'Разблокируйте огромное богатство сегодня!'
  },
  contact: {
    title: 'Создать аккаунт',
    firstNamePlc: 'Имя',
    lastNamePlc: 'Фамилия',
    emailPlc: 'Почта',
    phonePlc: 'Номер',
    buttonText: 'Регистрация'
  },
  joinUs: {
    title: 'Начните торговать биткойнами как профессионал, зарегистрировавшись в {trademark} Cегодня!',
    text1: 'Путешествие в клуб миллионеров начинается с первого простого шага. Вам нужно действовать прямо сейчас, чтобы сформировать свое финансовое будущее.',
    text2: 'Тысячи людей уже обрели финансовую свободу, торгуя с нами. За последние три года мы помогли бесчисленному количеству людей заработать на торговле биткойнами деньги, которые изменили их жизнь. Лучше всего то, что наша система очень проста в использовании для всех.',
    text3: 'Вам нужно только зарегистрироваться, а все остальное мы сделаем за вас. {trademark} это инструмент мечты для тех, кто ищет прибыльный инструмент пассивного дохода в Интернете.',
    asSeen: 'Как видно на'
  },
  testimonial: {
    title: 'TESTIMONIALS FROM HAPPY CLIENTS',
    client1: {
      name: 'Колин Б.',
      city: 'Будапешт',
      profit: 'Прибыль: $ {sum} XNUMX',
      testimonial: '«Торговля биткойнами изменила мою жизнь. Я был на грани того, чтобы сдаться и объявить о банкротстве, пока не наткнулся на {trademark} приложение. В течение нескольких месяцев торговли выплачивается не менее 80% моих долгов, и у меня стабильный ежедневный доход ».'
    },
    client2: {
      name: 'Алексия Р.',
      city: 'Эдмонтон',
      profit: 'Прибыль: $ {sum} XNUMX',
      testimonial: '«Пандемия covid19 была для меня скрытым благословением. Я начал использовать {trademark} от скуки, но теперь это мой основной источник дохода. Прибыль в размере до 5000 долларов в день - это то, чего я никогда не думал, что смогу достичь в этой экономике ».'
    },
    client3: {
      name: 'Тони Р.',
      city: 'Дубай',
      profit: 'Прибыль: $ {sum} XNUMX',
      testimonial: '«Это торговое приложение просто потрясающее. Я зарабатываю в десять раз больше, чем когда-либо работал по 12 часов в день продавцом страховых услуг. Самое удивительное, что я все еще могу получать прибыль, даже когда сплю ».'
    },
    client4: {
      name: 'Лори М.',
      city: 'Белфаст',
      profit: 'Прибыль: $ {sum} XNUMX',
      testimonial: '«Я зарегистрировался в {trademark} после прочтения его обзора в одном из ведущих криптографических изданий. Удивительно, насколько прибыльной может быть торговля на волатильности с помощью этого робота. Я получаю больше прибыли, чем когда-либо получал на торговле акциями ».'
    }
  },
  features: {
    feature1: {
      title: 'ПРОСТОТА ИСПОЛЬЗОВАНИЯ ПЛАТФОРМЫ',
      text: '{trademark} Приложение является веб-приложением. Вы можете получить к нам доступ из основных браузеров как на настольных, так и на мобильных устройствах. В целях безопасности мы рекомендуем использовать проверенные браузеры, такие как Chrome, Firefox, Safari и Brave. Вы можете скачать HTML5 версию нашего веб-трейдера для своих мобильных устройств.'
    },
    feature2: {
      title: 'ПРЕВОСХОДНЫЕ ФУНКЦИИ БЕЗОПАСНОСТИ',
      text: 'Мы ценим безопасность наших пользователей и поэтому инвестировали в защищенные от дурака системы. На нашем веб-сайте используется шифрование военного уровня, и мы соблюдаем глобальные меры защиты данных. К ним относятся меры высшего уровня, такие как Общий регламент ЕС по защите данных (GDPR). Наши брокеры также имеют хорошую репутацию и регулируются во всех юрисдикциях.'
    },
    feature3: {
      title: 'ДОСТУПНЫЙ ТОРГОВЫЙ КАПИТАЛ',
      text: 'Большинство наших конкурирующих торговых систем принимают только инвесторов с торговым капиталом не менее 1 миллиона долларов. Это означает, что они недоступны для большинства людей. Мы являемся первой торговой системой на основе искусственного интеллекта, которая позволяет трейдерам участвовать в автоматической торговле с депозитом всего в 250 долларов США.'
    }
  },
  live: {
    btnJoin: 'ПРИСОЕДИНЯЙСЯ СЕЙЧАС!',
    title: 'РЕЗУЛЬТАТЫ РЕАЛЬНОЙ ПРИБЫЛИ',
    table: {
      col1: {
        th: 'Самый большой {trademark}',
        row1: 'Жак С. только что выиграл торговлю ...',
        row2: 'Джейми В. только что выиграл сделку ...',
        row3: 'Дом Э. только что выиграл обмен ...',
        row4: 'Джек Т. только что выиграл сделку ...',
        row5: 'Иззи В. только что выиграла сделку ...',
        row6: 'Поппи Б. только что выиграла сделку ...',
        row7: 'Эдвард Х. только что выиграл сделку ...',
        row8: 'Лаура М. только что выиграла торговлю ...',
        row9: 'Салли П. только что выиграла сделку ...',
        row10: 'Рэйчел С. только что выиграла сделку ...'
      },
      col2: { th: 'Прибыль' },
      col3: { th: 'Время торговли' },
      col4: { th: 'Криптовалюта' },
      col5: { th: 'Результат' },
    }
  },
  howItWorks: {
    title: 'Как {trademark} работает?',
    contactBtn: 'Открыть бесплатный счет',
    step1: {
      cardText: 'Шаг 1',
      title: 'Зарегистрироваться на сайте',
      text: 'Зарегистрируйте бесплатный торговый счет, используя форму вверху этой страницы. Процесс прост и занимает менее 5 минут. Мы обрабатываем данные всех наших пользователей со строгой конфиденциальностью.'
    },
    step2: {
      cardText: 'Шаг 2',
      title: 'Пополнить счет',
      text: 'После регистрации мы перенаправим вас на страницу нашего брокера-партнера, чтобы внести средства на ваш торговый счет. Все наши финансовые операции осуществляются через авторитетных, строго регулируемых брокеров.'
    },
    step3: {
      cardText: 'Шаг 3',
      title: 'Начать торговлю',
      text: 'Живая торговля происходит одним нажатием кнопки. Вы можете заниматься своими повседневными делами, поскольку наша торговая система работает для вас в фоновом режиме. Депозит в размере 250 долларов - это все, что нужно, чтобы начать свой путь к успешному криптотрейдеру.'
    }
  },
  faqTop: {
    title: 'ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ',
    item1: {
      question: 'Is {trademark} новичок дружелюбный?',
      answer: 'Мы - простая в использовании и чрезвычайно прибыльная торговая система. Ниже приведены некоторые особенности, которые выделяют нас среди конкурентов.'
    },
    item2: {
      question: 'Какую прибыль я могу получить с  {trademark}?',
      answer: 'Для последовательных и удачливых трейдеров депозит в 250 долларов может вырасти до 1 миллиона долларов менее чем за год. Все формы торговли сопряжены со значительными рисками, поэтому вам следует инвестировать с умом.'
    },
    item3: {
      question: 'Сколько стоит {trademark}?',
      answer: 'Мы предлагаем бесплатные слоты для регистрации на основе лотереи, так что испытайте удачу, попытавшись зарегистрироваться через форму выше. Те, кому удастся получить бесплатную лицензию, будут торговать бесплатно в течение следующего года.'
    },
    item4: {
      question: 'Нужен ли мне торговый опыт для использования {trademark}?',
      answer: 'Вы можете зарабатывать деньги через {trademark} с первого дня торговли даже при нулевом торговом опыте. Это потому, что наша торговая система выполняет все технические торговые функции на автопилоте.'
    },
    item5: {
      question: 'Является ли {trademark} схемой Понци?',
      answer: 'Нет! {trademark} это не схема Понци. Многие люди задают этот вопрос из-за безумной прибыльности, о которой сообщает этот бот. Мы используем технологии искусственного интеллекта и высокое кредитное плечо, предоставляемое нашими брокерами-партнерами, чтобы обеспечить такую ​​высокую прибыльность.'
    },
    item6: {
      question: 'Сколько времени нужно, чтобы торговать?',
      answer: '{trademark} торгует автоматически, поэтому для настройки условий торговли требуется не более 20 минут в день. Это означает, что пользователям не нужно отказываться от своей дневной работы или проводить бессонные ночи, наблюдая за рынками.'
    }
  },
  faqBottom: {
    title: 'Глубокое погружение в {trademark}',
    whatIs: {
      title: 'Что такое {trademark}?',
      text: '{trademark} это мощный алгоритм искусственного интеллекта, созданный на основе секретных торговых стратегий, разработанных профессиональными трейдерами. Наши основатели принесли инвесторам огромную прибыль, используя передовые торговые системы.'
    },
    howDoes: {
      title: 'Как работает {trademark}?',
      text1: '{trademark} алгоритмы автоматизируют более 15 торговых стратегий, применяемых в динамичной торговле. К ним относятся скальпинг, метод, который включает в себя программу, которая размещает десятки сделок в течение микросекунды и извлекает прибыль из небольших ценовых движений. ',
      text2: 'Результат небольшого движения цены увеличивается за счет кредитного плеча 5000: 1, предоставляемого нашими брокерами-партнерами. Для справки, кредитное плечо 5000: 1 означает, что вы можете торговать позициями на сумму 5000 долларов на каждый вложенный доллар.',
      text3: '1. Зарегистрируйтесь с {trademark} через форму выше и получите подтверждение своей учетной записи, подтвердив свои контактные данные. Вы перенаправляете одного из наших брокеров с хорошей репутацией для проверки личности и адреса. Это важная мера безопасности, применяемая всеми ведущими брокерами.',
      text4: '2. Пополните свой счет, чтобы иметь возможность размещать заказы на рынках. Помните, что минимальный баланс составляет 250 долларов США, и все заказы размещаются автоматически. Вы можете торговать до подтверждения, но обратите внимание, что ваша учетная запись должна быть подтверждена, чтобы иметь возможность выводить средства.',
      text5: '3. Читать {trademark} руководство по торговле и проверьте свое понимание демоверсии. На этом этапе вам также будет назначен специальный менеджер по работе с клиентами, который поможет вам в процессе торговли.',
      text6: '4. Включите робота, сядьте поудобнее и расслабьтесь, пока деньги текут на ваш счет. При благоприятных торговых условиях, {trademark} может увеличить торговый счет в пять раз.'
    },
    facts: {
      title: 'Интересные факты о {trademark}',
      text1: 'В сентябре 2008 года Lehman Brothers, четвертый по величине инвестиционный банк в то время, объявил о банкротстве. Системный риск, связанный с субстандартными ипотечными кредитами, сказывался на банках, и у Lehman был наибольший риск.',
      text2: 'Деньги инвесторов на миллиарды долларов были потеряны, а сотни людей остались без работы. Наш основатель в то время был старшим портфельным менеджером в единственном хедж-фонде Уолл-Стрит и основателем продвинутой системы торговли производными финансовыми инструментами.',
      text3: 'Падение Lehman стало поворотным моментом для {trademark} торговое программное обеспечение после того, как оно принесло более 80 миллиардов долларов в течение 24 часов. В следующие три года система должна была принести инвесторам почти один триллион долларов.',
      text4: 'В 2016 году он запатентовал алгоритм и решил использовать его для торговли биткойнами. Только в 2016 году {trademark} стал доступен широкой публике. Во время биткойн-бума 2016/2017 года робот принес более 500,000 XNUMX миллиардов долларов прибыли.',
      text5: 'Благодаря нам многие люди получили финансовую свободу. Вы также можете стать частью нашей истории, подписавшись сегодня.'
    },
    famousThinks: {
      title: 'Поддержали ли Илон Маск и Гордан Рэмси {trademark}?',
      text1: 'Некоторые блоги, посвященные сплетням, писали вирусные сообщения, в которых утверждали, что мы получили инвестиции от известных предпринимателей. Но так ли это?',
      text2: '<b>{trademark} и Илон Маск -</b> Илон Маск - технический предприниматель, который серьезно интересуется искусственным интеллектом и блокчейном. В его инвестиционном портфеле действительно есть биткойны, но он не обещал нам никаких денег.',
      text3: '<b>{trademark} и Гордон Рамзи -</b> Знаменитый британский повар тоже не входит в число знаменитостей, вложивших много денег в нашу торговую систему.',
      text4: 'Есть также слухи, что {trademark} появлялся в Shark Tank, Dragons Den UK и Good Morning America. Мы не участвовали ни в одном из этих шоу.'
    },
    review: {
      title: '{trademark} Обзор: Вердикт!',
      text1: 'Наша торговая система имеет процент выигрышей 88% и уровень точности 90%. Это означает, что почти 9 из XNUMX людей, которые пытаются нас, зарабатывают деньги. Высокое кредитное плечо в сочетании с высокой точностью приносит астрономическую прибыль.',
      text2: '{trademark} поддерживается авторитетными брокерами-роботами, в чьи обязанности входит содействие торговле. Наши надежные партнерские брокеры обрабатывают все депозиты, сделанные через нашу платформу. Ты можешь попробовать {trademark} для себя, прокрутив страницу вверх и зарегистрировав бесплатную учетную запись через предоставленную форму.'
    }
  },
  footer: {
    btnGetStarted: 'Начать сейчас',
    text: 'Юридическое предупреждение: Торговля CFD, будь то вручную или через бот с высокой долей заемных средств, сопряжена с множеством рисков. Следовательно, рискуйте только тем, что вы можете позволить себе потерять. Информация, представленная на этой странице, не должна использоваться в качестве основы для принятия решений. Перед тем, как зарегистрироваться у нас, проведите достаточное предварительное исследование.Регистрируясь у нас, вы соглашаетесь с нашими условиями и политикой конфиденциальности. Не регистрируйтесь у нас, если вы против того, чтобы мы получали комиссию, связывая вас со сторонними платформами.'
  }
}
