import _en from './en';
import _ru from './ru';
import _de from './de';

const messages = {
  en: _en,
  ru: _ru,
  de: _de
}

export default messages;