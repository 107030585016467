export default {
  header: {
    title: 'Exklusive Angebote für Sie nur heute. Melden Sie sich in Ihrem kostenlosen Account an',
    profileText: 'gerade gemacht'
  },
  hero: {
    title: 'VOLATILITÄT BEIM BITCOIN-HANDEL ALS EIN VORTEIL {trademark}',
    subtitle: 'Entsperren Sie heute die unermesslichen Reichtümer!'
  },
  contact: {
    title: 'Erstellen Sie einen Account',
    firstNamePlc: 'Vorname',
    lastNamePlc: 'Familienname',
    emailPlc: 'E-Mail-Adresse',
    phonePlc: 'Telefon',
    buttonText: 'Melden Sie sich jetzt an'
  },
  joinUs: {
    title: 'Starten Sie den Bitcoin-Handel wie ein Profi heute mit der Anmeldung bei  {trademark} ',
    text1: 'Die Reise zum Club der millionenschweren Menschen beginnt mit dem ersten einfachen Schritt. Sie müssen jetzt Maßnahmen ergreifen, um Ihre finanzielle Zukunft zu bilden.',
    text2: 'Tausende von Menschen haben anhand dieses Handels ihre finanzielle Unabhängigkeit erreicht. In den letzten drei Jahren haben wir mit dem Bitcoin-Handel der nichtabzählbaren Menge von Menschen beim Verdienst der Gelder, die ihr Leben geändert haben, geholfen. Das Beste besteht darin, dass unser System für alle bedienfreundlich ist.',
    text3: '"Sie müssen sich nur anmelden, die Restarbeit nehmen wir ab. {trademark} ist das Traummittel für alle, die nach einem lukrativen Online-Mittel für passive Erträge suchen.',
    asSeen: 'Gerade wie'
  },
  testimonial: {
    title: 'REFERENZEN DER GLÜCKLICHEN KUNDEN',
    client1: {
      name: 'Collin B.',
      city: 'Budapest',
      profit: 'Gewinn $ {sum}',
      testimonial: '“Bitcoin-Handel hat mein Leben verändert. Ich war kurz vor der Tür der Gefallenen und für zahlungsunfähig erklärt, bis dahin die App in meinen Weg gelaufen {trademark} ist. In wenigen Monaten des Handels habe ich mindestens 80% meiner Schulden bezahlt und jetzt habe ich ein stabiles Einkommen jeden Tag.”'
    },
    client2: {
      name: 'Alexia R.',
      city: 'Edmonton',
      profit: 'Gewinn $ {sum}',
      testimonial: '"Die Covid-19-Pandemie brachte mir das Glück im Unglück. Aus Langeweile habe ich gestartet, diese App zu nutzen {trademark}, aber jetzt ist es meine Haupterwerbsquelle. Ein Gewinn bis zu 5.000 US-Dollar pro Tag ist etwas legendäres. Ich habe nie geglaubt, dass ich dies erreichen würde.”'
    },
    client3: {
      name: 'Tony R.',
      city: 'Dubai',
      profit: 'Gewinn $ {sum}',
      testimonial: '"Diese Handels-App ist einfach staunenswert. Ich verdiene zehnmal so viel als ich früher 12 Stunden am Tag als Versicherungskaufmann tätig war. Am staunenswertesten kann ich den Gewinn sogar im Schlaff erzielen."'
    },
    client4: {
      name: 'Laure M.',
      city: 'Belfast',
      profit: 'Gewinn $ {sum}',
      testimonial: '“Ich habe mich bei {trademark} angemeldet, nachdem ich die Übersicht in einer der führenden Kryptopublikationen gelesen hatte. Es ist überraschend, welchen Vorteil die Volatilität beim Bitcoin-Handel mit diesem Roboter haben kann. Ich mache mehr Gewinne als ich zuvor im Aktienhandel gemacht hatte.”'
    }
  },
  features: {
    feature1: {
      title: 'BEDIENFREUNDLICHE PLATTFORM',
      text: '{trademark} Die App ist webbasiert. Sie haben den Zugang aus den größten Browsers sowohl auf den Tischcomputern als auch auf den Mobilgeräten. Wir empfehlen, aus den Sicherheitsgründen angesehene Browsers wie Chrome, Firefox, Safari und Brave auszunutzen. Sie können die HTML5-Version unseres Web-Traders für Ihre Mobilgeräte herunterladen.'
    },
    feature2: {
      title: 'QUALITATIV HOCHSTEHENDE SICHERHEITSMERKMALE',
      text: 'Wir schätzen die Sicherheit unserer Benutzer und verfügen über absolut sichere Systeme. Unsere Webseite verfügt über das Verschlüsselungsprotokoll für militärische Kommunikation und wir halten die globalen Datenschutzmaßnahmen ein. Dazu gehören die qualitativ hochstehenden Maßnahmen gemäß der EU-Datenschutzgrundverordnung (DSGVO). Unsere Makler sind in allen gerichtlichen Zuständigkeitsbereichen ehrenwert und reglementiert.'
    },
    feature3: {
      title: 'KOSTENGÜNSTIGES HANDELSKAPITAL',
      text: 'Die meisten unserer konkurrierenden Handelssysteme akzeptieren nur die Investoren mit minimalem Handelskapital von 1 Million US-Dollar. Dies bedeutet, dass sie für die meisten Menschen außer Reichweite sind. Wir sind das erste AI-basierte Handelssystem, das es Händlern ermöglicht, mit der Einlage von nur 250 US-Dollar an dem Autohandel teilzunehmen.'
    }
  },
  live: {
    btnJoin: 'Schließen Sie sich jetzt',
    title: 'AKTIVER GEWINNERGEBNIS',
    table: {
      col1: {
        th: 'The {trademark}',
        row1: 'Jacque S. hat gerade den Zuschlag erhalten...',
        row2: 'Jamie W. hat gerade den Zuschlag erhalten...',
        row3: 'Dom E. hat gerade den Zuschlag erhalten...',
        row4: 'Jack T. hat gerade den Zuschlag erhalten...',
        row5: 'Izzie W. hat gerade den Zuschlag erhalten...',
        row6: 'Poppy B. hat gerade den Zuschlag erhalten...',
        row7: 'Edward H. hat gerade den Zuschlag erhalten...',
        row8: 'Laura M. hat gerade den Zuschlag erhalten...',
        row9: 'Sally P. hat gerade den Zuschlag erhalten...',
        row10: 'Rachael S. hat gerade den Zuschlag erhalten...'
      },
      col2: {
        th: 'Gewinn'
      },
      col3: {
        th: 'Handelszeit'
      },
      col4: {
        th: 'Kryptowährung'
      },
      col5: {
        th: 'Ergebnis'
      },
    }
  },
  howItWorks: {
    title: 'Wie funktioniert {trademark} dies?',
    contactBtn: 'Erstellen Sie einen Account kostenlos',
    step1: {
      cardText: '1. Schritt',
      title: 'Melden Sie sich auf der Web-Seite an',
      text: 'Registrieren Sie einen kostenlose Handelsaccount mittel des Formulars, das oben dieser Seite verfügbar ist. Der Prozess ist unkompliziert und dauert weniger als 5 Minuten. Wir verarbeiten alle Daten unserer Benutzer streng vertraulich.'
    },
    step2: {
      cardText: '2. Schritt',
      title: 'Finanzieren Sie Ihren Account',
      text: 'Nach der Anmeldung werden wir Sie auf die Seite unseres Partnermaklers umadressieren, um die Einlage auf Ihren Handelsaccount einzuzahlen. Alle unseren Finanztransaktionen werden durch die ehrenwerten und reglementierten Makler verarbeitet.'
    },
    step3: {
      cardText: '3. Schritt',
      title: 'den Handel starten',
      text: 'Aktiver Handel erfolgt mit dem Knopfdruck. Sie können Ihrem täglichen Geschäft nachgehen, weil unser Handelssystem im Hintergrund statt Ihnen tätig ist. Eine Einlage von 250 US-Dollar ist alles, was Sie brauchen, um Ihren Weg als erfolgreicher Krypto-Händler zu starten.'
    }
  },
  faqTop: {
    title: 'HÄUFIG GESTELLTE FRAGEN',
    item1: {
      question: 'Ist {trademark} es unkompliziert für den Anfänger?',
      answer: 'Unsere Handelssysteme sind einfach zu bedienendes und überaus ertragreich. Im Weiteren stehen einige der Merkmale, die uns ertragreicher zwischen unseren Wettbewerber machen.'
    },
    item2: {
      question: 'Welchen Gewinn kann ich machen? {trademark}?',
      answer: 'Für stetige und glückliche Händler kann eine Einlage von 250 US-Dollar durch die Aufzinsung für weniger als ein Jahr bis zu 1 Million US-Dollar wachsen. Bei allen Handelsformen bestehen erhebliche Risiken, demgemäß sollen Sie klugerweise investieren.'
    },
    item3: {
      question: 'Wie teuer ist es {trademark}?',
      answer: 'Wir bieten kostenlose Registrierungsslots auf Grund der Lotterie an. Versuchen Sie Ihr Glück und melden Sie sich mittels des obigen Formulars an. Diejenigen, die die kostenlose Lizenz sichergestellt haben, werden im kommenden Jahr kostenlos handeln.'
    },
    item4: {
      question: 'Brauche ich die Fachkenntnisse im Handelsbereich{trademark}?',
      answer: 'Sie können das Geld am ersten Handelstag {trademark} auch ohne Handelserfahrung verdienen. Dies besteht darin, dass unser Handelssystem alle technischen Handelsfunktionen auf dem Autopiloten durchführt.'
    },
    item5: {
      question: 'Ist {trademark} es ein Ponzi-Schema?',
      answer: 'Nein! {trademark} das ist kein Ponzi-Schema. Viele Leute stellen diese Frage wegen der unvorstellbaren Ertragsfähigkeit, die dieses Bot zur Kenntnis gebracht hat. Wir verwenden die Technologien der künstlichen Intelligenz und des hohen Verschuldungsgrades, die von unseren Partnermaklern gewährleistet wurden, um die hohe Ertragsfähigkeit sicherzustellen.'
    },
    item6: {
      question: 'Wie lange dauer der Handel?',
      answer: '{trademark} der Handel erfolgt automatisch. Sie brauchen nicht mehr als 20 Minuten pro Tag, um die Bedingungen für den Handel aufzustellen. Dies bedeutet, dass die Benutzer nicht auf ihre gewöhnliche Berufstätigkeit verzichten oder schlaflose Nächte bei der Kontrolle der Märkte verbringen.'
    }
  },
  faqBottom: {
    title: 'Komplexe Analyse {trademark}',
    whatIs: {
      title: 'Was ist {trademark}?',
      text: '{trademark} ist ein starker Algorithmus, der aus den geheimen Handelsstrategien besteht, die von professionellen Händlern entwickelt wurden. Unsere Gründer haben mit Hilfe der fortschrittlichen Handelssystemen den reichen Gewinn für die Investoren erzielt.'
    },
    howDoes: {
      title: 'Wie das {trademark} funktioniert?',
      text1: '{trademark}Die Algorithmen gelten als die Automatisierung über 15 Handelsstrategien, die im schnellen Handel angewendet werden. Dazu gehört das Scalping (Kursmanipulation durch Empfehlungen oder Scheinorders). Das ist eine Technik, bei der das Programm Dutzende der Händler innerhalb einer Mikrosekunde platziert und erzielt den Gewinn von kleinen Preisänderungen.',
      text2: 'Das Ergebnis der kleinen Preisänderung wird durch die Hebelwirkung von 5000:1 vergrößert, die von unseren Partnermaklern gewährleistet wird. Es ist zu bemerken, eine Hebelwirkung von 5000:1 bedeutet an, dass Sie die Positionen im Wert von 5.000 US-Dollar für jeden investierten 1 US-Dollar handeln können.',
      text3: '1. Melden Sie sich mit {trademark} mittels des obigen Formulars an und Ihr Account wird nach dem Überprüfen Ihrer Kontaktdaten genehmigt. Sie werden zu unserem hochehrenwerten Makler umadressieren, um Ihre ID und Adresse zu überprüfen. Dies ist eine entscheidende Sicherheitsmaßnahme, die von allen Top-Maklern eingeführt wird.',
      text4: '2. Finanzieren Sie Ihren Account, um Bestellungen auf den Märkten aufzugeben. Es ist Ihnen anzunehmen, dass das erforderliche Mindestbilanz von 250 US-Dollar ist und alle Bestellungen automatisch aufgegeben werden. Sie können vor der Bestätigung handeln. Aber es ist darauf zu beachten, dass Ihr Account überprüft werden muss, um das Geld vom Konto abzuheben.',
      text5: '3. Lesen Sie {trademark} die Handelsanleitung und prüfen Sie, dass die Demo-Version für Sie verständlich ist. In dieser Phase werden Sie als ein fest zugeordneter Account-Manager zugewiesen, der Ihnen die Hilfe beim Handelsprozess leisten wird.',
      text6: '4. Schalten Sie den Roboter ein, entspannen Sie sich und genießen die Zeit, wenn  das Geld auf Ihren Account eingeht. Unter den begünstigenden Handelsbedingungen kann {trademark} der Handelskontostand um das Fünffache vergrößert werden.'
    },
    facts: {
      title: 'Interessante Fakten über {trademark}',
      text1: 'Im September 2008 wurde Lehman Brothers, die damals viertgrößte Investmentbank, für zahlungsunfähig erklärt. Das systemische Risiko, das im bonitätsschwachen Hypothekenkredit bestand, hat die Banken schwere Verluste zugefügt, und Lehman Brothers befand sich in der Hochrisikoposition.',
      text2: 'Die Geldmittel der Investoren im Betrag von Milliarden US-Dollar wurden verloren und Hunderte von Menschen blieben ohne Beschäftigung. Unser Gründer war zu dieser Zeit Senior-Portfolio-Manager bei dem Wall Street Spekulationsfonds und Gründer eines fortschrittlichen Handelssystems für Finanzderivate.',
      text3: 'Die Situation mit Lehman Brothers war ein Wendepunkt für die {trademark} Handelssoftware, die mehr als 80 Milliarden US-Dollar pro 24 Stunden erzielt hat. In den nächsten drei Jahren wird das System fast eine Trillion US-Dollar für die Investoren erzielen.',
      text4: 'Im Jahre 2016 hat er den Algorithmus durch Patent geschützt und entschied sich, ihn für den Bitcoin-Handel auszunutzen. Bis zum Jahr 2016 war {trademark} er im Blickpunkt der Öffentlichkeit nicht verfügbar. Während des Bitcoin-Aufschwung in den Jahren 2016-2017 erzielte der Roboter den Gewinn über 500.000 Milliarden US-Dollar.',
      text5: 'Viele Menschen haben anhand dieses Handels ihre finanzielle Unabhängigkeit erlangt. Sie können auch Teil unserer Geschichte werden, falls Sie sich heute anmelden.'
    },
    famousThinks: {
      title: 'Haben Elon Musk und Gordan Ramsey diesen Handel {trademark} genehmigt?',
      text1: 'Einige Klatschblogs haben virale Posts geschrieben, in denen behauptet wird, dass unsere Investoren die berühmten Unternehmer sind. Stimmt es??',
      text2: '<b>{trademark} und Elon Musk –</b> Elon Musk ist ein Unternehmer im Bereich der Hochtechnologien mit riesigen Interessen im AI und Blockchain. Er hat einige Bitcoins in seinem Anlageportfolio, aber er hat kein Geldmittel in unserem System gebunden.',
      text3: '<b>{trademark} und Gordon Ramsay –</b>  - ein britischer Koch, der nicht zur Anzahl der Berühmtheiten gehört, die viel Geld in unser Handelssystem eingezahlt haben',
      text4: 'Ein Gerede geht darum {trademark} , dass sich in the Shark Tank, Dragons Den UK und Good Morning America gezeigt hat. Wir waren nicht durch diese Shows gekennzeichnet.'
    },
    review: {
      title: '{trademark} Übersicht: Erkenntnis!',
      text1: 'Unser Handelssystem hat den Gewinnsatz von 88% und der Genauigkeitsgrad beträgt von 90%. Dies bedeutet, dass fast 9 von zehn Menschen den Verdienst der Geldmittel bei uns versuchen. Hohe Hebelwirkung gemeinsam mit hoher Genauigkeit führt zu den riesengroßen Gewinnen.',
      text2: '{trademark} wird von hochehrenwerten Robotermaklern angetrieben, deren Aufgabe in der Erleichterung des Handels besteht. Unsere äußerst kreditfähigen Partnermakler führen alle Einzahlungen über unsere Plattform aus. Sie können {trademark} dies selbstständig zu versuchen. Gelangen Sie zum Anfang dieser Seite, finden Sie das bereitgestellte Formular und melden Sie einen Account kostenlos an.'
    }
  },
  footer: {
    btnGetStarted: 'Anfangen',
    text: '<b>Disclaimer:</b> CFDs-Handel sowohl manuell als auch anhand des vorwiegend mit Fremdkapital finanzierten Bots, einschließlich mit rieseigen Risiken. Infolgedessen das einzige Risiko besteht in Ihrem Verlust. Die dargestellten Informationen auf dieser Seite sind nicht als Grundlage für das Fällen von Entscheidungen zu entnehmen. Machen Sie eigene Forschungen bevor der Anmeldung in unserem Handelssystem. Mit Ihrer Anmeldung sind Sie mit unseren Allgemeinen Geschäftsbedingungen und Datenschutzbestimmungen einverstanden. Melden Sie sich nicht bei uns an, wenn Sie dagegen sind, dass wir eine Provision aus Ihrer Anbindung zu den Plattformen von Drittanbietern verdienen.' 
  }
}